exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-work-djdosa-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/manzanotree/manzanotree/src/work/djdosa.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-work-djdosa-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-work-warmregards-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/manzanotree/manzanotree/src/work/warmregards.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-work-warmregards-mdx" */)
}

